<template>
  <div class="mt-5" v-if="!isEmptyCoupon">
    <h2>
      <div class="d-block d-sm-none" style="font-size: 70%">
        Udemyの動画講義を割引価格で買おう！
      </div>
      <div class="d-none d-sm-block">Udemyの動画講義を割引価格で買おう！</div>
    </h2>
    <span style="margin: 30px" />
    <v-row>
      <!-- featuredカード -->
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        v-for="coupon in featured_coupon"
        :key="coupon.id"
      >
        <a :href="coupon.url" target="_blank" class="text-decoration-none">
          <v-card hover ripple>
            <v-img :src="coupon.imagePath" max-height="300px" contain />
            <v-card-title class="py-0 px-2">
              <font size="3">{{ coupon.title }}</font>
            </v-card-title>
            <v-card-text>
              <div style="text-align: right">
                <font size="3" class="red--text mr-n2">
                  \ {{ coupon.discountPercent }}%OFF /
                </font>
              </div>
              <div style="text-align: right">
                <nobr>
                  <s size="2" class="s-bold-red; mr-1">
                    ¥{{ coupon.regular_price.toLocaleString() }}
                  </s>
                  →
                  <font
                    size="5"
                    style="
                      color: #e66700;
                      font-weight: bold;
                      margin-right: -5px;
                    "
                  >
                    ¥{{ coupon.custom_price.toLocaleString() }}
                  </font>
                </nobr>
              </div>
            </v-card-text>
          </v-card>
        </a>
      </v-col>
      <!-- commonカード -->
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="3"
        v-for="coupon in common_coupon"
        :key="coupon.id"
      >
        <a :href="coupon.url" target="_blank" class="text-decoration-none">
          <v-card hover ripple>
            <v-card-title class="py-0 px-2">
              <font size="3">{{ coupon.title }}</font>
            </v-card-title>
            <v-card-text>
              <div style="text-align: right">
                <font size="3" class="red--text mr-n2">
                  \ {{ coupon.discountPercent }}%OFF /
                </font>
              </div>
              <div style="text-align: right">
                <nobr>
                  <s size="2" class="s-bold-red; mr-1">
                    ¥{{ coupon.regular_price.toLocaleString() }}
                  </s>
                  →
                  <font
                    size="5"
                    style="
                      color: #e66700;
                      font-weight: bold;
                      margin-right: -5px;
                    "
                  >
                    ¥{{ coupon.custom_price.toLocaleString() }}
                  </font>
                </nobr>
              </div>
            </v-card-text>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
h2 {
  padding: 0.25em 0.5em; /*上下 左右の余白*/
  background: transparent; /*背景透明に*/
  border-left: solid 5px #e66700; /*左線*/
}
</style>

<script>
export default {
  name: 'UdemyCoupons',
  data() {
    return {
      featured_coupon: [],
      common_coupon: [],
      isEmptyCoupon: false,
    };
  },
  async created() {
    await this.getCouponInfo();
    this.$emit('isLoaded', true);
  },
  methods: {
    async getCouponInfo() {
      let { obj_coupons: couponInfo } = await this.utils_get_firestore(
        'publicData',
        'udemy',
      );
      if (Object.keys(couponInfo).length === 0) {
        this.isEmptyCoupon = true;
        return;
      }

      couponInfo = Object.entries(couponInfo).reduce((prev, curr) => {
        const [key, value] = curr;
        const queryParam = this.$route.query.udemy;
        return key.match(queryParam) ? { ...prev, [key]: value } : prev;
      }, {});

      const arraObj_coupon = this.utils_create_arrayObjFromObj(couponInfo);
      for (const item in arraObj_coupon) {
        const fullPath = `udemyCoupons/${arraObj_coupon[item].id}.jpg`;
        arraObj_coupon[item].imagePath = this.utils_create_storageUrl(fullPath);
        arraObj_coupon[item].discountPercent = this.calc_discount(
          arraObj_coupon[item].custom_price,
          arraObj_coupon[item].regular_price,
        );
      }

      this.featured_coupon = arraObj_coupon.filter((data) => data.featured);
      this.common_coupon = arraObj_coupon.filter((data) => !data.featured);
    },
    calc_discount(customPrice, regularPrice) {
      const discountedPercent = (1 - customPrice / regularPrice) * 100;
      const roundedDown = Math.floor(discountedPercent);
      return roundedDown;
    },
  },
};
</script>
