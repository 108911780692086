<template>
  <v-container v-show="isPageComplete">
    <!--科目カード-->
    <v-row style="margin-top: 10px">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="ele in arrayObj_kamoku"
        :key="ele.id"
        style="margin-top: -10px"
      >
        <v-sheet outlined color="#e66700" rounded v-if="ele.status == 'public'">
          <v-card hover ripple @click="pageTransition(ele.id)">
            <v-img :src="ele.url" max-height="300px" @load="loaded" contain />
            <v-card-title class="justify-center">
              <font size="3">{{ ele.text }}</font>
            </v-card-title>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <!--マイアカウント-->
    <v-row style="margin-top: -20px">
      <v-col cols="6" sm="4" md="3" lg="2" v-if="isLogin">
        <v-card hover ripple @click="pageTransition('/myAcount')">
          <v-img src="@/assets/myAcount.jpg" max-height="300px" contain />
          <v-card-title><font size="3">マイアカウント</font></v-card-title>
        </v-card>
      </v-col>
      <!-- ログインページへ -->
      <v-col cols="6" sm="4" md="3" lg="2" v-if="!isLogin">
        <v-card hover ripple @click="gf_moveToRoot()">
          <v-img src="@/assets/login.jpg" max-height="300px" contain />
          <v-card-title><font size="3">ログインページへ</font></v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!--Udemyクーポン-->
    <div ref="couponArea" class="coupon-area">
      <UdemyCoupons @isLoaded="isCouponLoaded = $event" />
    </div>
  </v-container>
</template>

<style>
.coupon-area {
  height: calc(100vh - 200px);
  margin-top: 60px;
}
</style>

<script>
import { mapState } from 'vuex';
import UdemyCoupons from '../compPromotion/UdemyCoupons.vue';
export default {
  name: 'Home',
  components: { UdemyCoupons },
  data() {
    return {
      arrayObj_kamoku: [], //科目カード
      isPageComplete: false, //処理がすべて終わったらtrueにして要素を表示
      loadedCnt: 0, //科目カードの読み込み枚数を監視する
      isCouponLoaded: false, //クーポン画像をロードできるとemitされる
    };
  },
  computed: {
    ...mapState(['webApp', 'user']),
    isLogin() {
      const email = this.user.auth.email;
      if (email == undefined) {
        return false;
      } else {
        return true;
      }
    },
    isAllLoaded() {
      return this.isPageComplete && this.isCouponLoaded;
    },
  },
  watch: {
    isAllLoaded(value) {
      if (value) {
        this.$store.commit('loading', false);
      }
    },
  },
  async mounted() {
    this.$store.commit('loading', true);

    //状態維持
    await this.gf_keepActive();
    await this.gf_keepLogin();

    await this.create_kamokuCards();

    this.$store.commit('mainHeader', true);
    this.$store.commit('hamburger', false);
    this.isPageComplete = true;
  },
  updated() {
    if (this.$route.query.udemy) {
      this.scrollToEnd();
    }
  },
  methods: {
    scrollToEnd() {
      this.$nextTick(() => {
        const container = this.$refs.couponArea;
        container.scrollIntoView();
      });
    },
    async create_kamokuCards() {
      const obj = await this.utils_get_firestore('publicData', 'conf');
      const arrayObj_kamoku = this.utils_create_arrayObjFromObj(
        obj.itemsKamoku,
      );
      arrayObj_kamoku.forEach((ele) => {
        const fullPath = 'kamokuImg/' + ele.img_name;
        ele.url = this.utils_create_storageUrl(fullPath);
      });
      this.arrayObj_kamoku = arrayObj_kamoku;
    },
    pageTransition(path) {
      this.$store.commit('hamburger', true);
      this.$router.push(path);
    },
    //科目カードを読み込み数を監視する
    loaded() {
      ++this.loadedCnt;
      const totalKamokuNumber = this.arrayObj_kamoku.filter(
        (kamoku) => kamoku.status === 'public',
      ).length;
      if (totalKamokuNumber === this.loadedCnt) {
        this.$store.commit('loading', false);
      }
    },
  },
  created() {
    const existingInflowOrigin = localStorage.getItem('inflowOrigin');
    const param = this.$route.query;
    if (Object.keys(param).length && !existingInflowOrigin) {
      const media = Object.keys(this.$route.query)[0];
      const inflowOrigin = {
        date: new Date().getTime(),
        media,
        param: param[media],
      };
      localStorage.setItem('inflowOrigin', JSON.stringify(inflowOrigin));
    }
  },
};
</script>
